import CheckboxPill from "./checkboxPill"
import Layout from "./layout"

type Value<T> = T

type Option<T> = {
  label: string
  value: T
}

type CheckboxesProps<T> = {
  column?: boolean
  options: Option<T>[]
  onChange: (values: Value<T>[]) => void
  values: Value<T>[]
}

const Checkboxes = <T,>(props: CheckboxesProps<T>) => {
  const filteredOptions = props.options.filter((option) => {
    return props.values.includes(option.value)
  })

  const allSelected = filteredOptions.length === props.options.length
  const someSelected = filteredOptions.length > 0

  return (
    <Layout
      gap={8}
      wrap={props.column ? false : true}
      direction={props.column ? "v" : "h"}
    >
      <CheckboxPill
        label="All"
        isChecked={allSelected}
        isIndeterminate={someSelected}
        onChange={(checked) =>
          checked
            ? props.onChange(props.options.map((option) => option.value))
            : props.onChange([])
        }
      />
      {props.options.map(({ label, value }) => {
        return (
          <CheckboxPill
            key={label}
            label={label}
            isChecked={props.values.includes(value)}
            onChange={(checked) => {
              props.onChange(
                checked
                  ? [...props.values, value]
                  : props.values.filter((f) => f !== value)
              )
            }}
          />
        )
      })}
    </Layout>
  )
}

export default Checkboxes
