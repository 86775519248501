import Link from "next/link"
import { useRouter } from "next/router"
import Layout from "./layout"
import Text from "./text"

export default function Navbar() {
  return (
    <Layout gap={16} justify="spaceBetween">
      <Layout gap={16}>
        <NavLink to="/">Worklist</NavLink>
        <NavLink to="/customers">Customers</NavLink>
      </Layout>

      <div>{process.env.NEXT_PUBLIC_APP_VERSION}</div>
    </Layout>
  )
}

const NavLink = ({
  children,
  to,
}: {
  children: React.ReactNode
  to: string
}) => {
  const { pathname } = useRouter()

  const isActive = pathname === to

  return (
    <Layout direction="v" align="center" gap={4}>
      <Link href={to}>
        <Text color={isActive ? "white" : "grey"}>{children}</Text>
      </Link>
      <Dot color={isActive ? "white" : undefined} />
    </Layout>
  )
}

const Dot = ({ color }: { color?: string }) => (
  <div
    style={{
      height: "4px",
      width: "4px",
      borderRadius: "50%",
      background: color || "inherit",
    }}
  ></div>
)
