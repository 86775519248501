import ReactSelect from "react-select"
import { Options, SingleValue } from "react-select"

type Option = {
  label: string
  value: string
}

type Props = {
  value: string
  onChange: (value: string) => void
  options?: Options<Option>
}

export default function Select({ options, value, onChange }: Props) {
  const getValue = () => {
    if (options) {
      return options.find((option) => option.value === value)
    } else {
      return ""
    }
  }

  const handleChange = (option: SingleValue<string | Option>) => {
    if (typeof option === "string") {
      onChange(option)
    } else {
      onChange(option?.value || "")
    }
  }

  return (
    <ReactSelect
      options={options}
      isSearchable
      value={getValue()}
      onChange={handleChange}
      styles={{
        container(base, props) {
          return {
            ...base,
            width: "100%",
          }
        },
        control(base, props) {
          return {
            ...base,
            background: "#363636",
            border: "1px solid #2F2F2F",
            // color: "red",
            borderRadius: "4px",
          }
        },
        input(base, props) {
          return {
            ...base,
            color: "#E1E1E1",
          }
        },
        singleValue(base, props) {
          return {
            ...base,
            color: "#E1E1E1",
          }
        },
        option(base, props) {
          return {
            ...base,
            // borderRadius: "4px",
            backgroundColor: props.isSelected
              ? "#0D77EF"
              : props.isFocused
              ? "rgba(255,255,255,.15)"
              : "#363636",
          }
        },
        menu(base, props) {
          return {
            ...base,
            boxShadow: "rgba(0, 0, 0, 0.75) 0px 5px 15px",
          }
        },
        menuList(base, props) {
          // console.log(base)
          return {
            ...base,
            background: "#363636",
            borderRadius: "4px",
            border: "1px solid rgb(100,100,100)",
            padding: 0,
          }
        },
      }}
    />
  )
}
