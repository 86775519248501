import { ReactNode } from "react"
import Layout from "./layout"

type Props = {
  children: ReactNode
}

export default function InputLayout(props: Props) {
  return (
    <Layout direction="v" gap={8}>
      {props.children}
    </Layout>
  )
}
