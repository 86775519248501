type Props = {
  color?: string
  children: React.ReactNode
  lineHeight?: number
  size?: number
}

export default function Text(props: Props) {
  return (
    <div
      style={{
        color: props.color,
        fontSize: props.size || "inherit",
        lineHeight: props.lineHeight,
      }}
    >
      {props.children}
    </div>
  )
}
