type Quad<T> = T | [T, T] | [T, T, T, T]

interface Props {
  align?: "center" | "end"
  borders?: Quad<string>
  borderRadius?: Quad<number>
  children: React.ReactNode
  direction?: "v" | "h"
  fill?: string
  flex?: string
  gap?: number
  height?: number | string
  justify?: "spaceBetween" | "center"
  padding?: Quad<number>
  maxHeight?: number | string
  maxWidth?: number | string
  minHeight?: number | string
  minWidth?: number | string
  width?: number | string
  wrap?: boolean
  overflowY?: "visible" | "hidden" | "scroll" | "auto"
}

const Layout = (props: Props) => {
  const padding = translateQuad(props.padding)
  const borderRadius = translateQuad(props.borderRadius)
  const borders = translateQuad(props.borders)

  return (
    <div
      style={{
        border: "test",
        alignItems: props.align === "end" ? "flex-end" : props.align,
        backgroundColor: props.fill,
        justifyContent:
          props.justify === "spaceBetween" ? "space-between" : props.justify,
        borderTop: borders[0],
        borderRight: borders[1],
        borderBottom: borders[2],
        borderLeft: borders[3],
        borderTopLeftRadius: borderRadius[0],
        borderTopRightRadius: borderRadius[1],
        borderBottomRightRadius: borderRadius[2],
        borderBottomLeftRadius: borderRadius[3],
        display: "flex",
        flex: props.flex,
        flexDirection: props.direction === "v" ? "column" : "row",
        flexWrap: props.wrap ? "wrap" : "nowrap",
        gap: props.gap,
        height: props.height,
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
        minHeight: props.minHeight,
        minWidth: props.minWidth,
        width: props.width,
        paddingTop: padding[0],
        paddingRight: padding[1],
        paddingBottom: padding[2],
        paddingLeft: padding[3],
        overflowY: props.overflowY,
      }}
    >
      {props.children}
    </div>
  )
}

export default Layout

const translateQuad = <T,>(quad?: Quad<T>) => {
  if (quad === undefined) {
    return [undefined, undefined, undefined, undefined]
  }

  if (Array.isArray(quad)) {
    if (quad.length === 2) {
      const arr = [quad[0], quad[1], quad[0], quad[1]]
      return arr
    }

    if (quad.length === 4) {
      return quad
    }
  }

  return [quad, quad, quad, quad]
}
