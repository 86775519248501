import { ChangeEvent, HTMLInputTypeAttribute } from "react"

type Props = {
  onChange: (value: string) => void
  type?: HTMLInputTypeAttribute
  value: string
  prefix?: string
}

export default function Input(props: Props) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.value)
  }
  return (
    <input
      value={(props.prefix || "") + props.value}
      onChange={handleChange}
      type={props.type}
      style={{
        appearance: "none",
        // background: "#222222",
        background: "black",
        border: "1px solid #2F2F2F",
        borderRadius: "4px",
        color: props.value === "" ? "#949494" : "#E1E1E1",
        fontSize: "inherit",
        padding: "8px",
        width: "100%",
      }}
    />
  )
}
