import { inferRouterOutputs } from "@trpc/server"
import { DateTime } from "luxon"
import Link from "next/link"
import { useEffect, useMemo, useState } from "react"
import { AppRouter } from "../server/routers/_app"
import collectUniqueValues from "../utils/collectUniqueValues"
import { trpc } from "../utils/trpc"
import Button from "./button"
import Checkboxes from "./checkboxes"
import Input from "./input"
import InputLabel from "./inputLabel"
import InputLayout from "./inputLayout"
import Layout from "./layout"
import LoadingIndicator from "./loadingIndicator"
import Select from "./select"
import Text from "./text"

type RouterOutput = inferRouterOutputs<AppRouter>
type Worklist = RouterOutput["worklist"]

interface Filters {
  purposeOfReferral: string[]
  isUrgent: boolean[]
  isOnTime: boolean[]
  isDoubleRead: boolean[]
  isExternalRead: boolean[]
  invoicingCompanyName: string[]
  radiologyProvider: string[]
  modality: string[]
}

export default function Invoicer() {
  const utils = trpc.useContext()
  const [isInvoicing, setIsInvoicing] = useState(false)
  const [filters, setFilters] = useState<Filters>({
    purposeOfReferral: [],
    isUrgent: [],
    isOnTime: [],
    isDoubleRead: [],
    isExternalRead: [],
    invoicingCompanyName: [],
    radiologyProvider: [],
    modality: [],
  })

  const [account, setAccount] = useState("")
  const [taxCode, setTaxCode] = useState("")
  const [item, setItem] = useState("")
  const [price, setPrice] = useState("")

  const deleteWorklistMutation = trpc.deleteWorklist.useMutation({
    onSuccess: () => {
      utils.worklist.setData(undefined, null)
    },
  })

  const myobCustomersQuery = trpc.myob.customers.useQuery(undefined, {
    staleTime: Infinity,
  })

  const accountsQuery = trpc.myob.accounts.useQuery(undefined, {
    staleTime: Infinity,
  })
  const taxCodesQuery = trpc.myob.taxCodes.useQuery(undefined, {
    staleTime: Infinity,
  })
  const itemsQuery = trpc.myob.items.useQuery(undefined, {
    staleTime: Infinity,
  })

  const worklistQuery = trpc.worklist.useQuery(undefined, {
    staleTime: Infinity,
  })

  const customerLinksQuery = trpc.customerLinks.useQuery(undefined, {
    staleTime: Infinity,
  })

  const worklist =
    worklistQuery.data && customerLinksQuery.data && myobCustomersQuery.data
      ? worklistQuery.data.items.map((item) => {
          const link = customerLinksQuery.data.find(
            (link) => link.portalId === item.invoicingCompanyId
          )

          return {
            ...item,
            myobCustomerUid: link
              ? myobCustomersQuery.data.find(
                  (customer) => customer.id === link.myobId
                )?.id
              : null,
            isActive: link ? link.isActive : true,
          }
        })
      : undefined

  const worklistOutstanding = worklist?.filter(
    (item) =>
      item.myobCustomerUid !== null &&
      item.isInvoiced === false &&
      item.isActive
  )

  const hasUnlinkedCustomers = !!worklist?.find((item) => !item.myobCustomerUid)

  const uniqueValues = collectUniqueValues(worklistOutstanding || [])

  const filteredWorklist = useMemo(() => {
    return (
      worklistOutstanding?.filter((worklistItem) => {
        let filterKey: keyof Filters
        for (filterKey in filters) {
          const test = worklistItem[filterKey]
          const filterValues: any[] = filters[filterKey]
          if (!filterValues.includes(test)) {
            return false
          }
        }

        return true
      }) || []
    )
  }, [filters, worklistOutstanding])

  useEffect(() => {
    const selectedItem = itemsQuery.data?.find(({ id }) => {
      return id === item
    })

    if (selectedItem) {
      setAccount(selectedItem.incomeAccountId)
      setTaxCode(selectedItem.taxCodeId)
      setPrice(selectedItem.price.toString())
    }
  }, [item, itemsQuery.data])

  const createInvoiceMutation = trpc.createInvoice.useMutation({
    onError: (e) => {
      alert(e.message)
      setIsInvoicing(false)
    },
    onSuccess: (data, variables) => {
      utils.worklist.setData(undefined, (old) => {
        const idx = old?.items.findIndex(
          (x) => x.id === variables.worklistItemId
        )

        if (!old || idx === undefined) {
          return old
        }

        return {
          ...old,
          items: [
            ...old.items.filter((r, i) => i !== idx),
            { ...old.items[idx], isInvoiced: true },
          ],
        }
      })
    },
  })

  const invoiceHandler = async () => {
    setIsInvoicing(true)
  }

  useEffect(() => {
    if (isInvoicing && filteredWorklist?.length === 0) {
      setIsInvoicing(false)
    }

    if (
      isInvoicing &&
      filteredWorklist &&
      filteredWorklist.length > 0 &&
      !createInvoiceMutation.isLoading
    ) {
      const selectedWorklistItem = filteredWorklist[0]

      const selectedItem = itemsQuery.data?.find((x) => x.id === item)

      if (!selectedItem) {
        alert("No Selected Item")
        return
      }

      const description =
        selectedItem.name +
        " - Reported on " +
        new Date(selectedWorklistItem.reportCompletedDate).toLocaleDateString(
          "en-AU"
        ) +
        " - " +
        selectedWorklistItem.patientName +
        " - " +
        selectedWorklistItem.lungscreenId +
        " - Referred by " +
        selectedWorklistItem.referringDoctor +
        " - " +
        selectedWorklistItem.referringClinic

      if (selectedWorklistItem.myobCustomerUid === undefined) {
        alert("An error occured")
        return
      }

      createInvoiceMutation.mutate({
        date: DateTime.now().toString().split("T")[0],
        worklistItemId: selectedWorklistItem.id,
        accountUid: account,
        itemUid: item,
        taxCodeUid: taxCode,
        customerUid: selectedWorklistItem.myobCustomerUid || "",
        price: parseFloat(price),
        description,
      })
    }
  }, [
    isInvoicing,
    filteredWorklist,
    account,
    item,
    taxCode,
    price,
    createInvoiceMutation,
    itemsQuery.data,
  ])

  if (isInvoicing) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          background: "#181818",
          top: 0,
          left: 0,
          right: 0,
          minHeight: "90vh",
        }}
      >
        <Layout direction="v" maxWidth={400} flex="1" gap={16}>
          <h2>Invoicing</h2>
          <div>{filteredWorklist?.length} Remaining</div>
          <Button onClick={() => setIsInvoicing(false)}>Cancel</Button>
        </Layout>
      </div>
    )
  }

  return (
    <Layout gap={16} flex="1" overflowY="hidden">
      <Layout
        padding={16}
        borderRadius={10}
        borders="1px solid #303030"
        direction="v"
        maxWidth={400}
        flex="1"
        // align="center"
        gap={16}
      >
        <Layout justify="spaceBetween">
          <h2>Overview</h2>
          <Button onClick={() => deleteWorklistMutation.mutate()}>
            <Text>New Worklist</Text>
          </Button>
        </Layout>
        {worklistQuery.isLoading ? (
          "Loading"
        ) : worklistQuery.error || !worklistQuery.data ? (
          "Error"
        ) : (
          <Layout direction="v" gap={16}>
            {/* <ProgressBar
              readings={[
                {
                  name: "Invoiced",
                  value: progress,
                  color: "#21ac4e",
                },
                {
                  name: "Selected",
                  value: filteredWorklist.length,
                  color: "#9ec9ac",
                },
                {
                  name: "Not Invoiced",
                  value:
                    worklist.data.length -
                    worklist.data.filter((w) => w.isInvoiced).length,
                  color: "rgb(40,40,40)",
                },
              ]}
            /> */}
            {/* <Text>
              {`${Math.round(
                (worklist.data.filter((w) => w.isInvoiced).length /
                  worklist.data.length) *
                  100
              )}%`}
            </Text> */}
            <Layout justify="spaceBetween">
              <Text>Progress</Text>
              <Text>
                {worklistQuery.data.items.filter((i) => i.isInvoiced).length} /{" "}
                {worklistQuery.data.items.length}
              </Text>
            </Layout>
            <Layout justify="spaceBetween">
              <Text>Created At</Text>
              <Text>
                {worklistQuery.data.createdAt
                  ? new Date(worklistQuery.data.createdAt).toLocaleDateString()
                  : "Unknown"}
              </Text>
            </Layout>
            <Layout justify="spaceBetween">
              <Text>KPI Start Date</Text>
              <Text>
                {worklistQuery.data.kpiStartFrom
                  ? DateTime.fromISO(worklistQuery.data.kpiStartFrom, {
                      setZone: true,
                    }).toLocaleString()
                  : "Any"}
                -{" "}
                {worklistQuery.data.kpiStartTo
                  ? DateTime.fromISO(worklistQuery.data.kpiStartTo, {
                      setZone: true,
                    }).toLocaleString()
                  : "Any"}
              </Text>
            </Layout>
            <Layout justify="spaceBetween">
              <Text>Report Completed Date</Text>
              <Text>
                {worklistQuery.data.reportCompletedFrom
                  ? DateTime.fromISO(worklistQuery.data.reportCompletedFrom, {
                      setZone: true,
                    }).toLocaleString()
                  : "Any"}{" "}
                -{" "}
                {worklistQuery.data.reportCompletedTo
                  ? DateTime.fromISO(worklistQuery.data.reportCompletedTo, {
                      setZone: true,
                    }).toLocaleString()
                  : "Any"}
              </Text>
            </Layout>
          </Layout>
        )}
      </Layout>
      <Layout
        direction="v"
        width="600px"
        borders="1px solid #303030"
        borderRadius={10}
        padding={16}
        gap={16}
        overflowY="auto"
      >
        <h2>Filters</h2>
        {hasUnlinkedCustomers && (
          <Layout
            borderRadius={10}
            borders="2px solid #FFCC4A"
            fill="#ffcc4a2b"
            gap={8}
            padding={8}
          >
            <Text lineHeight={1.2}>⚠️</Text>
            <Text color="#FFCC4A">
              Not all customers are showing.{" "}
              <Link href="/customers">Link them to a MYOB customer.</Link>
            </Text>
          </Layout>
        )}
        {worklistQuery.isLoading ||
        customerLinksQuery.isLoading ||
        myobCustomersQuery.isLoading ? (
          <Layout align="center" justify="center" flex="1">
            <LoadingIndicator />
          </Layout>
        ) : worklistQuery.error ||
          customerLinksQuery.error ||
          myobCustomersQuery.error ? (
          <Layout align="center" justify="center" flex="1">
            <Text>Error</Text>
          </Layout>
        ) : worklistOutstanding?.length === 0 ? (
          <Layout align="center" justify="center" flex="1">
            <Text>Nothing to Filter</Text>
          </Layout>
        ) : (
          <>
            <Layout gap={8} direction="v">
              <Text>Purpose of Referral</Text>
              <Checkboxes
                values={filters.purposeOfReferral}
                options={uniqueValues.purposeOfReferral.map(
                  (purposeOfReferral) => ({
                    label: purposeOfReferral,
                    value: purposeOfReferral,
                  })
                )}
                onChange={(values) =>
                  setFilters({ ...filters, purposeOfReferral: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Text>Modality</Text>
              <Checkboxes
                values={filters.modality}
                options={uniqueValues.modality.map((modality) => ({
                  label: modality,
                  value: modality,
                }))}
                onChange={(values) =>
                  setFilters({ ...filters, modality: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Text>Is Urgent</Text>
              <Checkboxes
                values={filters.isUrgent}
                options={uniqueValues.isUrgent.map((isUrgent) => ({
                  label: isUrgent ? "Urgent" : "Routine",
                  value: isUrgent,
                }))}
                onChange={(values) =>
                  setFilters({ ...filters, isUrgent: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Text>Is On Time</Text>
              <Checkboxes
                values={filters.isOnTime}
                options={uniqueValues.isOnTime.map((isOnTime) => ({
                  value: isOnTime,
                  label: isOnTime ? "On Time" : "Late",
                }))}
                onChange={(values) =>
                  setFilters({ ...filters, isOnTime: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Text>Is Double Read</Text>
              <Checkboxes
                values={filters.isDoubleRead}
                options={uniqueValues.isDoubleRead.map((isDoubleRead) => ({
                  value: isDoubleRead,
                  label: isDoubleRead ? "Double Read" : "Single Read",
                }))}
                onChange={(values) =>
                  setFilters({ ...filters, isDoubleRead: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Text>Is External Read</Text>
              <Checkboxes
                values={filters.isExternalRead}
                options={uniqueValues.isExternalRead.map((isExternalRead) => ({
                  value: isExternalRead,
                  label: isExternalRead
                    ? "External Read"
                    : "Internal Read Only",
                }))}
                onChange={(values) =>
                  setFilters({ ...filters, isExternalRead: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Layout justify="spaceBetween">
                <Text>Invoicing Company</Text>
              </Layout>

              <Checkboxes
                column
                values={filters.invoicingCompanyName}
                options={
                  uniqueValues.invoicingCompanyName &&
                  uniqueValues.invoicingCompanyName.sort().map((name) => ({
                    label: name,
                    value: name,
                  }))
                }
                onChange={(values) =>
                  setFilters({ ...filters, invoicingCompanyName: values })
                }
              />
            </Layout>
            <Layout gap={8} direction="v">
              <Layout justify="spaceBetween">
                <Text>Radiology Provider</Text>
              </Layout>

              <Checkboxes
                column
                values={filters.radiologyProvider}
                options={
                  uniqueValues.radiologyProvider &&
                  uniqueValues.radiologyProvider.sort().map((name) => ({
                    label: name,
                    value: name,
                  }))
                }
                onChange={(values) =>
                  setFilters({ ...filters, radiologyProvider: values })
                }
              />
            </Layout>
          </>
        )}
      </Layout>
      <Layout
        direction="v"
        maxWidth={400}
        flex="1"
        borders="1px solid #303030"
        borderRadius={10}
        padding={16}
        gap={16}
        overflowY="auto"
      >
        <h2>{`Create Invoices (${filteredWorklist?.length})`}</h2>
        {filteredWorklist.length === 0 ? (
          <Layout align="center" justify="center" flex="1">
            <Text>Nothing Selected</Text>
          </Layout>
        ) : accountsQuery.isLoading ||
          itemsQuery.isLoading ||
          taxCodesQuery.isLoading ? (
          <Layout align="center" justify="center" flex="1">
            <LoadingIndicator />
          </Layout>
        ) : (
          <>
            <InputLayout>
              <InputLabel label="Item" />
              <Select
                options={itemsQuery.data?.map((item) => ({
                  label: item.number + "   " + item.name,
                  value: item.id,
                }))}
                value={item}
                onChange={setItem}
              />
            </InputLayout>
            <InputLayout>
              <InputLabel label="Account" />
              <Select
                options={accountsQuery.data?.map((account) => ({
                  label: account.displayId + " " + account.name,
                  value: account.id,
                }))}
                value={account}
                onChange={setAccount}
              />
            </InputLayout>

            <InputLayout>
              <InputLabel label="Tax Code" />
              <Select
                options={taxCodesQuery.data?.map((taxCode) => ({
                  label: taxCode.code + " - " + taxCode.description,
                  value: taxCode.id,
                }))}
                value={taxCode}
                onChange={setTaxCode}
              />
            </InputLayout>
            <InputLayout>
              <InputLabel label="Price (GST Inclusive)" />
              <Input value={price} onChange={setPrice} type="number" />
            </InputLayout>
            <Button
              onClick={() => {
                invoiceHandler()
              }}
              variant="highlighted"
              isDisabled={
                createInvoiceMutation.isLoading ||
                !(item && account && taxCode && price)
              }
            >
              {createInvoiceMutation.isLoading ? "Creating" : "Create"}
            </Button>
          </>
        )}
      </Layout>
    </Layout>
  )
}
