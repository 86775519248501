type Variant = "highlighted"

type Props = {
  children: React.ReactNode
  onClick?: () => void
  variant?: Variant
  isDisabled?: boolean
}

const getVariantStyle = (variant?: Variant) => {
  if (!variant)
    return {
      background: "#363636",
      color: "#FCFBFC",
      border: "1.5px solid #282828",
    }

  if (variant === "highlighted")
    return {
      background: "#0D77EF",
      color: "white",
    }
}

export default function Button(props: Props) {
  return (
    <button
      disabled={props.isDisabled}
      onClick={props.onClick}
      style={{
        alignItems: "center",
        border: "none",
        borderRadius: "4px",
        cursor: props.isDisabled ? "not-allowed" : "pointer",
        display: "flex",
        fontSize: "1rem",
        fontFamily: "inherit",
        gap: "12px",
        height: "36px",
        justifyContent: "center",
        lineHeight: 1,
        opacity: props.isDisabled ? 0.5 : 1,
        padding: "0px 12px",
        userSelect: "none",
        ...getVariantStyle(props.variant),
      }}
    >
      {props.children}
    </button>
  )
}
