import Layout from "./layout"
import { useEffect, useRef } from "react"

type Props = {
  isChecked: boolean
  isDisabled?: boolean
  isIndeterminate?: boolean
  label: string
  onChange: (newValue: boolean) => void
}

export default function CheckboxPill(props: Props) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof props.isIndeterminate === "boolean") {
      ref.current.indeterminate = !props.isChecked && props.isIndeterminate
    }
  }, [ref, props.isIndeterminate, props.isChecked])

  return (
    <label
      style={{
        cursor: props.isDisabled ? "not-allowed" : "pointer",
        userSelect: "none",
      }}
    >
      <Layout
        align="center"
        gap={8}
        height={36}
        fill="#363636"
        borderRadius={6}
        padding={[0, 12]}
      >
        <input
          type="checkbox"
          disabled={props.isDisabled}
          ref={ref}
          checked={props.isChecked}
          onChange={() => props.onChange(!props.isChecked)}
          style={{ cursor: props.isDisabled ? "not-allowed" : "pointer" }}
        />
        <div style={{ color: "#FCFBFC" }}>{props.label}</div>
      </Layout>
    </label>
  )
}
