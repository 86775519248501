import { useState } from "react"
import { trpc } from "../utils/trpc"
import Button from "./button"
import Heading from "./heading"
import Input from "./input"
import Layout from "./layout"
import Text from "./text"
import { DateTime } from "luxon"

const RefreshWorklist = ({ onSuccess }: { onSuccess: () => void }) => {
  const [kpiStartFrom, setKpiStartFrom] = useState("")
  const [kpiStartTo, setKpiStartTo] = useState("")
  const [reportCompletedFrom, setReportCompletedFrom] = useState("")
  const [reportCompletedTo, setReportCompletedTo] = useState("")

  const updateWorklistMutation = trpc.refreshWorklist.useMutation({
    onSuccess,
    onError: (e) => alert(e.message),
  })

  return (
    <Layout width="100%" minHeight="80vh" align="center" justify="center">
      <Layout
        direction="v"
        borderRadius={10}
        width="100%"
        maxWidth="fit-content"
        gap={16}
      >
        <Heading>New Worklist</Heading>
        <Layout width="100%" gap={8} align="center">
          <Layout flex="1">{}</Layout>
          <Layout width={140}>
            <Text>From</Text>
          </Layout>
          <Layout width={140}>
            <Text>To</Text>
          </Layout>
        </Layout>
        <Layout width="100%" gap={8} align="center">
          <Layout flex="1">
            <Text>KPI Start Date</Text>
          </Layout>
          <Layout width={140}>
            <Input
              value={kpiStartFrom}
              onChange={(value) => setKpiStartFrom(value)}
              type="date"
            />
          </Layout>
          <Layout width={140}>
            <Input
              value={kpiStartTo}
              onChange={(value) => setKpiStartTo(value)}
              type="date"
            />
          </Layout>
        </Layout>
        <Layout width="100%" gap={8} align="center">
          <Layout flex="1">
            <Text>Report Completed Date</Text>
          </Layout>
          <Layout width={140}>
            <Input
              value={reportCompletedFrom}
              onChange={(value) => setReportCompletedFrom(value)}
              type="date"
            />
          </Layout>
          <Layout width={140}>
            <Input
              value={reportCompletedTo}
              onChange={(value) => setReportCompletedTo(value)}
              type="date"
            />
          </Layout>
        </Layout>

        <Button
          variant="highlighted"
          isDisabled={updateWorklistMutation.isLoading}
          onClick={() => {
            updateWorklistMutation.mutate({
              kpiStartFrom: kpiStartFrom
                ? DateTime.fromISO(kpiStartFrom, { zone: "UTC" }).toJSDate()
                : undefined,
              kpiStartTo: kpiStartTo
                ? DateTime.fromISO(kpiStartTo, { zone: "UTC" })
                    .endOf("day")
                    .toJSDate()
                : undefined,
              reportCompletedFrom: reportCompletedFrom
                ? DateTime.fromISO(reportCompletedFrom, {
                    zone: "UTC",
                  }).toJSDate()
                : undefined,
              reportCompletedTo: reportCompletedTo
                ? DateTime.fromISO(reportCompletedTo, { zone: "UTC" })
                    .endOf("day")
                    .toJSDate()
                : undefined,
            })
          }}
        >
          {updateWorklistMutation.isLoading ? "Getting..." : "Go"}
        </Button>
      </Layout>
    </Layout>
  )
}

export default RefreshWorklist
