import React from "react"

import { trpc } from "../utils/trpc"

import RefreshWorklist from "../components/refreshWorklist"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import Invoicer from "../components/invoicer"
import LoadingIndicator from "../components/loadingIndicator"

export default function Home() {
  const { data, isLoading, error, refetch } = trpc.worklist.useQuery(
    undefined,
    {
      staleTime: Infinity,
    }
  )

  return (
    <Layout gap={16} padding={16} maxHeight="100vh" direction="v">
      <Navbar />
      {isLoading ? (
        <Layout flex="1" align="center" gap={16} justify="center">
          <LoadingIndicator />
        </Layout>
      ) : error ? (
        <Layout flex="1" align="center" gap={16} justify="center">
          {error.message}
        </Layout>
      ) : !data ? (
        <RefreshWorklist onSuccess={refetch} />
      ) : (
        <Invoicer />
      )}
    </Layout>
  )
}
