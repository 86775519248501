import { ReactNode } from "react"
import Text from "./text"

type Props = {
  children: ReactNode
}

export default function Heading(props: Props) {
  return <Text size={26}>{props.children}</Text>
}
