type UniqueValues<T> = {
  [K in keyof T]: T[K][]
}

export default function collectUniqueValues<T extends Record<string, any>>(
  arr: T[]
): UniqueValues<T> {
  const result: UniqueValues<T> = {} as UniqueValues<T>

  for (const obj of arr) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (!result[key as keyof T]) {
          result[key as keyof T] = []
        }

        if (!result[key as keyof T].includes(obj[key as keyof T])) {
          result[key as keyof T].push(obj[key as keyof T])
        }
      }
    }
  }

  return result
}
